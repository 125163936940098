@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap");
@import "./component/sass/fonts.scss";
@import "./component/sass/Variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  font-size: 16px;
  font-family: "Riffic Free", sans-serif;
}
body {
  overflow-x: hidden;
  font-size: 16px;
  background-color: $black-color;
}

:lang(ar) {
  letter-spacing: 0px !important;
}
:lang(tr) {
  font-family: "oceanwide-primer", sans-serif;
  // h2,h1,h3,h4,h5,h6{
  //   font-family: "oceanwide-primer",sans-serif;
  // }
  // span, button{
  //   font-family: "oceanwide-primer",sans-serif;
  // }
}

$rubik: "Rubik", sans-serif;

@media (min-width: 1200px) and (max-width: 1399px) {
  .container {
    max-width: 1240px;
  }
}

.posRelaitve {
  position: relative;
  z-index: 1;
}

.ptb {
  padding: 10em 0 6em;
  @media (max-width: 767px) {
    padding: 8em 0 2em;
  }
}

.home_ptb {
  padding: 4.5em 0 0em;
  @media (max-width: 767px) {
    padding: 8.1em 0 2em;
  }
}

.btn {
  border-radius: 10;
  color: $black-color;
  transition: 0.25s ease-out;
  &.btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    &:hover {
      border-color: $white-color;
      background-color: $white-color;
      color: $black-color;
    }
    &.fullWidth {
      width: 100%;
      line-height: 51px;
      height: 64px;
      letter-spacing: 4px;
      font-size: 1.5em;
      @media (max-width: 767px) {
        font-size: 1.1em;
      }
    }
  }
  &.btn-secondary {
    background-color: $white-color;
    border-color: $white-color;
    &:hover {
      border-color: $primary-color;
      background-color: $primary-color;
      color: $black-color;
    }
  }
}

.css-13cymwt-control,
.css-t3ipsp-control {
  width: 75px;
  background-color: transparent !important;
  border-width: 0 !important;
  border-color: transparent;
  box-shadow: none !important;
  font-family: $halogen;
  .css-qbdosj-Input {
    width: 50px;
  }
  .css-1dimb5e-singleValue {
    color: $white-color;
    font-weight: 400;
  }
}

.css-1fdsijx-ValueContainer {
  padding: 0;
  width: 50px;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-1hb7zxy-IndicatorsContainer {
  background-color: $light-black;
  border-radius: 5px;
}

.css-1xc3v61-indicatorContainer {
  color: $white-color !important;
}

.css-1nmdiq5-menu {
  background-color: $light-black;
  width: 75px !important;
}

.legal-pages {
  li,
  p {
    font-size: 1em;
    // font-weight: 400;
    font-family: $halogen;
  }
  h4.title {
    text-shadow: 7px 7px 0px $black-color;
    font-size: 2.5em;
    font-family: $riffic;
    position: relative;
    letter-spacing: 3.75px;
    -webkit-text-stroke: 1px $black-color;
    @media (max-width: 565px) {
      font-size: 2.125em;
    }
    &::after {
      position: absolute;
      content: "";
      background-image: url("../src/component/images/loginsvg.svg");
      bottom: 0;
      width: 11%;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      // top: -20px;
      left: -3px;
      z-index: 1;
    }
    color: mix($white-color, $white-color, 80%);
    span {
      position: relative;
      z-index: 2;
    }
  }
  :lang(ar) {
    h4.title {
      &::after {
        right: 0;
      }
    }
  }
  ol,
  ul {
    padding-left: 15px;
    li {
      line-height: 30px;
    }
  }
}
.modal::-webkit-scrollbar {
  display: none;
}
.modal-character-details {
  .modal-dialog {
    width: 70%;
    max-width: 70% !important;
  }
  .overview_body {
    .title {
      font-size: 5em;
      @media (max-width: 565px) {
        font-size: 4em;
        margin-bottom: 0.5em;
      }
      letter-spacing: 0;

      span {
        color: $white-color;
      }
      &::after {
        position: absolute;
        content: "";
        background-image: url("../src/component/images/loginsvg.svg");
        height: 80px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        top: -20px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }
}
h1.title {
  text-shadow: 7px 7px 0px $black-color;
  font-size: 3em;

  &.f-5 {
    font-size: 4em;
  }
  position: relative;
  letter-spacing: 3.75px;
  -webkit-text-stroke: 3px $black-color;
  &::after {
    position: absolute;
    content: "";
    background-image: url("../src/component/images/loginsvg.svg");
    bottom: 0;
    width: 100%;
    height: 108px;
    background-repeat: no-repeat;
    background-size: contain;
    top: -40px;
    left: -3px;
    z-index: -1;
  }
  color: mix($white-color, $white-color, 80%);
}
.shadow-title {
  text-shadow: 7px 7px 0px $black-color;
  font-size: 3em;

  &.f-5 {
    font-size: 4em;
  }
  position: relative;
  // letter-spacing: 3.75px;
  -webkit-text-stroke: 3px $black-color;
  color: mix($white-color, $white-color, 80%);
}

h2.title {
  text-shadow: 7px 7px 0px $black-color;
  font-size: 3.125em;
  position: relative;
  letter-spacing: 3.75px;
  -webkit-text-stroke: 3px $black-color;
  @media (max-width: 565px) {
    font-size: 2.125em;
  }
  &::after {
    position: absolute;
    content: "";
    background-image: url("../src/component/images/loginsvg.svg");
    bottom: 0;
    width: 20%;
    height: 108px;
    background-repeat: no-repeat;
    background-size: contain;
    top: -35px;
    left: -3px;
    z-index: 1;
  }
  color: mix($white-color, $white-color, 80%);
  span {
    position: relative;
    z-index: 2;
  }
}
:lang(ar) {
  .overview_main {
    h2.title {
      -webkit-text-stroke: 1px $black-color;
      &::after {
        right: 0;
      }
    }
  }

  .slider_box {
    .swiper {
      h2.title {
        &::after {
          top: 2px;
        }
      }
    }
  }

  .footer {
    h3 {
      &::before {
        left: -26px;
        height: 85px;
      }
    }
  }

  .slider_box {
    .swiper-slide {
      .btn {
        -webkit-text-stroke: 0 !important;
      }
    }
  }
}
.form-control:focus,
.form-control:active {
  box-shadow: none;
  border-color: $primary-color;
}

input.form-control {
  height: 64px;
  border-radius: 10px;
  font-size: 0.875em;
  padding-left: 1.5em;
  padding-right: 1.5em;

  font-family: $halogen;
  @media (max-width: 767px) {
    height: 50px;
    font-size: 0.8em;
  }
}
.form-check-input[type="checkbox"] {
  z-index: 99;
}
select.form-select {
  height: 64px;
  border-radius: 10px;
  font-size: 0.875em;
  padding-left: 1.5em;
  padding-right: 1.5em;

  font-family: $halogen;
  @media (max-width: 767px) {
    height: 50px;
    font-size: 0.8em;
  }
}

textarea.form-control {
  border-radius: 10px;
  font-size: 0.875em;
  padding: 15px;
  border-width: 1;
  font-family: $halogen;
}

/***************/

.navbar-expand-lg {
  padding-top: 0.875em;
  padding-bottom: 0.875em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  @media (max-width: 767px) {
    backdrop-filter: blur(10px);
  }
  .navbar-collapse {
    flex-direction: column;
  }
  .navbar-brand {
    img {
      width: 100%;
    }
  }
  .navbar-nav {
    .nav-link {
      color: $white-color;
      display: flex;
      align-items: center;
      font-size: 1.1em;
      margin-right: 2.1em;
      position: relative;

      &.active {
        color: $primary-color;
        svg {
          path {
            fill: $primary-color;
          }
        }
        &::after {
          position: absolute;
          content: "";
          background-image: url("../src/component/images/navimg.svg");
          bottom: 0;
          width: 100%;
          height: 29px;
          background-repeat: no-repeat;
          background-size: contain;
          top: 45px;
        }
      }
      &:hover {
        color: $primary-color;
      }
      svg {
        margin-right: 0.6em;
      }
    }
    .btnBox {
      margin-left: 2.2em;
      @media (max-width: 767px) {
        margin-left: 0;
      }
      .langSelect {
        font-family: $halogen;
        margin-right: 0.6em;
        color: $white-color;
        cursor: pointer;

        span {
          background-color: #28262d;
          padding: 7px 8px;
          border-radius: 5px;
          margin-left: 0.2em;
          transition: 0.25s ease-out;
          &:hover {
            background-color: $primary-color;
            svg {
              path {
                fill: $black-color;
              }
            }
          }
        }
      }
      .language {
        font-family: $halogen;
        color: $white-color;
      }
      .btn {
        padding: 0.8em 1.5em;
        letter-spacing: 1.5px;
        font-size: 0.875em;
        border-radius: 10px;
        &.newBtn {
          padding: 0.8em 1.5em !important;
          font-size: 1em !important;
          background-color: $primary-color !important;
          color: $black-color !important;
          &:hover {
            background-color: $white-color !important;
          }
        }
        &.newBtn1 {
          padding: 0.8em 1.5em !important;
          font-size: 1em !important;
          background-color: $white-color !important;
          color: $black-color !important;
          &:hover {
            background-color: $primary-color !important;
          }
        }
      }
      &.langFlex {
        flex-direction: row;
        display: flex;
        align-items: center;
        @media (max-width: 565px) {
          margin-top: 1em;
        }
        .css-b62m3t-container {
          margin-right: 1em;
        }
        .btn {
          margin-left: 0.6em;
          padding: 0;
          background-color: transparent;
          border-color: transparent;
          img {
            margin-right: 0.6em;
          }
          font-size: 1.4em;
          @media (max-width: 565px) {
            font-size: 0.8em !important;
          }
          color: rgba($color: $white-color, $alpha: 1);
          &:hover {
            color: $primary-color;
          }
        }
        .dropdown-menu {
          background-color: "#F3F1F8";
          margin-top: 0.5em;
          padding: 0;
          @media (max-width: 565px) {
            position: absolute;
          }
          a {
            transition: 0.25s ease-out;
            font-family: $halogen;
            padding: 0.4em 1em;
            border-radius: 5px;
            &:hover {
              color: $black-color;
              background-color: $primary-color;
            }
          }
          &::after {
            position: absolute;
            content: "";
            top: -17px;
            right: 3px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 20px solid rgba($color: $white-color, $alpha: 1);
            z-index: -1;
            @media (max-width: 565px) {
              right: initial;
              left: 0;
            }
          }
        }
      }
    }
  }
  &.is-sticky {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
  }
}

.form-check-input {
  margin-left: -1.5em;
  margin-right: 12px;
  width: 1.2em;
  height: 1.2em;
  &:checked {
    background-color: $primary-color;
    border-color: $primary-color;
  }
  &:active,
  &:focus {
    box-shadow: none;
  }
}

/* Login Page */
.bannerSection {
  position: relative;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    top: 0;
    background: linear-gradient(to top, $black-color 0%, transparent 100%);
  }

  &:after {
    bottom: 0;
    background: linear-gradient(to bottom, $black-color 0%, transparent 100%);
  }

  .bannerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: $black-color, $alpha: 0.5);
  }
}
.bannerBottomOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    360deg,
    // #0d0c0f 0.2%,
    rgba(13, 12, 15, 0.85) 1.55%,
    rgba(13, 12, 15, 0) 50.81%,
    // rgba(13, 12, 15, 0.28) 70.66%
  );
}
.episodeSection {
  position: relative;
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  width: 280px;
  border-radius: 18px;
  overflow: hidden;
}
.homeSection {
  position: relative;
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  .bannerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      359deg,
      #0d0c0f 0.83%,
      rgba(13, 12, 15, 0.85) 28.55%,
      rgba(13, 12, 15, 0) 48.81%,
      rgba(13, 12, 15, 0.28) 70.66%,
      #0d0c0f 103.18%
    );
  }

  .playBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 2;

    @media (max-width: 767px) {
      top: 60%;
    }

    @media (max-width: 565px) {
      top: 73%;
    }

    path {
      transition: 0.25s ease-out;
      fill: $white-color;
      &:hover {
        fill: $primary-color;
      }
    }
  }
}

.bannerContent {
  color: $white-color;
  .loginImg {
    height: 670px;
    width: 100%;
    object-fit: contain;
    @media (max-width: 767px) {
      height: 300px;
    }
  }
  .title {
    margin-bottom: 1.5em;
    @media (max-width: 767px) {
      margin-top: 2em;
    }
  }
  .forgotText {
    text-decoration: none;
    text-align: right;
    display: block;
    color: $white-color;
    letter-spacing: 0.5px;
    transition: 0.3s ease-out;
    @media (max-width: 767px) {
      font-size: 0.8em;
    }
    &:hover {
      color: $primary-color;
    }
  }
  .orText {
    text-align: center;
    font-family: $halogen;
  }
  .signupText {
    font-size: 1em;
    font-family: $halogen;
    text-align: center;

    a {
      font-family: $riffic;
      color: $primary-color;
      text-decoration: none;
      transition: 0.3s ease-out;
      &:hover {
        color: $white-color;
      }
    }
  }
  .btn {
    &.fullWidth {
      margin: 1.2em 0 1.6em;
      box-shadow: 7px 7px 0px 0px #854b1a;
      transition: all ease-in 0.2s;
      &:hover {
        box-shadow: none;
        transform: translateY(4px);
        transition: all ease-in 0.2s;
        opacity: 1;
      }
    }
  }
  &.singup {
    .title {
      margin-bottom: 0.5em;
    }
    .agreeText {
      color: $white-color;
      h5 {
        font-family: $halogen;
        font-size: 1em;
        a {
          font-family: $riffic;
          text-decoration: none;
          color: $white-color;
          transition: 0.25s ease-out;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
  .forgdesc {
    font-family: $halogen;
    font-size: 1em;
    letter-spacing: 1.2px;
    margin-bottom: 2.3em;
  }
  .optCode {
    margin-bottom: 2em;
    input {
      flex-grow: 1;
      height: 2em;
      font-size: 2em;
      border-radius: 10px;
      box-shadow: none;
      outline: none;
      border: 0;
      &:focus {
        background-color: $primary-color;
      }
    }
    .sep {
      margin: 0 0.5em;
    }
  }
  &.homeBannerContent {
    .loginImg {
      height: 600px;
      width: 100%;
      object-fit: contain;
      @media (max-width: 767px) {
        height: 300px;
      }
    }
    .homeBannerContent_box {
      .badge {
        font-family: $halogen;
        background-color: $black-color !important;
        border-radius: 100px;
        padding: 0.4em 0.8em;
      }
      .title {
        margin: 0.4em 0 0.1em;
        font-size: 5em;
        @media (max-width: 565px) {
          font-size: 4em;
          margin-bottom: 0.5em;
        }
        letter-spacing: 0;
        &::after {
          display: none;
        }
        span {
          color: $primary-color;
        }
      }
      .list_cat {
        .badge {
          font-family: $halogen;
          background-color: #4e4843 !important;
          border-radius: 5px;
          padding: 0.5em 1em;
          font-size: 0.9em;
          margin-right: 0.7em;
          &:last-child {
            margin-right: 0;
          }
          // &:nth-of-type(even){
          &:lang(ar) {
            margin-left: 0.7em;
          }
          // }
          @media (max-width: 565px) {
            margin-bottom: 0.6em;
          }
        }
      }
      .btn {
        margin-bottom: 7em;
        margin-top: 1em;
        @media (max-width: 565px) {
          margin-bottom: 1em;
          margin-top: 1em;
        }
        svg {
          position: relative;
          top: -2px;
          margin-right: 0.4em;
        }
      }
      .bannerBtns {
        .btn {
          letter-spacing: 0.15em;
          &.cBtn {
            border-radius: 10px;
            padding: 0.7em 1.6em;
            @media (max-width: 565px) {
              padding: 0.7em 1.2em;
            }
            font-size: 0.875em;
            &.btn-outline-primary {
              color: $white-color;
              border-color: $white-color;
              &:hover {
                background-color: $primary-color;
                border-color: $primary-color;
                color: $black-color;
              }
            }
          }
        }
      }
    }
  }
}

.passwordPos {
  position: relative;
  .form-control {
    padding-right: 3.4em;
  }
  svg {
    position: absolute;
    top: 21px;
    right: 19px;
    font-size: 1.4em;
    cursor: pointer;
  }
}

.sociallinks {
  display: flex;
  justify-content: center;
  margin: 1.9em 0;
  a {
    background-color: $white-color;
    width: 4em;
    height: 4em;
    @media (max-width: 767px) {
      width: 3em;
      height: 3em;
    }
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s ease-out;
    svg {
      path {
        fill: $black-color;
      }
    }
    &:hover {
      background-color: $primary-color;
    }
  }
}

.overview_main {
  background-color: $black-color;

  .overview_body {
    .title {
      margin-bottom: 1em;
      &::after {
        display: block;
        top: 2px;
      }
    }
    h3 {
      font-family: $rubik;
      font-weight: 200;
      color: rgba($color: $white-color, $alpha: 1);
      font-size: 0.875em;
      display: flex;
      flex-direction: row;

      span {
        margin-left: 1em;
        margin-bottom: 0.8em;
        &.fixWidth {
          margin-left: 0;
          width: 10em;
          font-weight: 500;
          @media (max-width: 565px) {
            width: auto;
          }
        }
        &.colorPrimary {
          color: $primary-color;
        }
      }
    }
    p {
      margin-top: -7px;
      font-family: $halogen;
      line-height: 29px;
      color: rgba($color: $white-color, $alpha: 1);
    }
  }

  &.ptop {
    padding-top: 4em;
  }

  &.overview_char {
    padding-top: 4em;
    padding-bottom: 4em;
  }
}

.slider_box {
  .swiper-slide {
    text-align: center;
    .btn {
      margin-top: 1.2em;
      width: 85%;
      text-shadow: 1px 1px 2px $black-color;
      position: relative;
      -webkit-text-stroke: 2px $black-color;
      color: $white-color;
      font-size: 1.6em;
      padding: 0.3em 0.4em;
      line-height: 1.3em;
      &:hover {
        color: $primary-color;
        -webkit-text-stroke: 2px $black-color;
      }
    }
  }
}
:lang(ar) {
  .slider_box .swiper-slide .btn {
    -webkit-text-stroke: 1px $black-color;
    &:hover {
      color: $primary-color;
      -webkit-text-stroke: 1px $black-color;
    }
  }

  h1.title,
  h2.title,
  span,
  h4.title {
    text-shadow: none;
    -webkit-text-stroke: 0px;
  }
}
.form-check-input {
  // top: 11px;
  // position: relative;
  border-color: #cdd5e0;
  border-width: 3px;
  &:checked {
    background-color: $primary-color;
    border-color: $black-color;
  }
}

.payments_main {
  overflow: hidden;
  h2 {
    text-align: center;
    margin-bottom: 2em;
    &:after {
      top: -15px;
      left: calc(100% - 64%);
    }
  }
  .nav {
    &.nav-tabs {
      .nav-link {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-color: transparent;
        font-size: 0.875em;
        padding: 0.6em 1.75em;
        color: $white-color;
        @media (max-width: 565px) {
          padding: 0.6em 1em;
        }
        &.active {
          background-color: $primary-color;
          color: $black-color;
        }
      }
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      border-bottom: none;
      border: 1px solid rgba($color: $white-color, $alpha: 1);
      margin: 0 auto 2em;
      border-radius: 10px;
      width: fit-content;
    }
  }
}

.table > :not(caption) > * > * {
  background-color: transparent;
}
.react_table {
  background-color: $black-bg;
  padding: 1em 2em;
  border-radius: 15px;
  @media (max-width: 565px) {
    padding: 1em 1em;
    font-size: 0.8em;
  }
  .responsiveTable {
    margin: 0;
    thead {
      tr {
        th {
          border-bottom: none;
          color: #a9a9a9;
          font-family: $halogen;
        }
      }
    }
    tbody {
      tr {
        td {
          color: $white-color;
          font-family: $halogen;
          padding: 1.2em 0em;
          border-bottom: 1px solid #222225;
          strong {
            font-family: $riffic;
          }
        }
      }
    }
    .actionBtn {
      .btn {
        margin: 0 0 0;
        box-shadow: 7px 7px 0px 0px $black-color;
        transition: all ease-in 0.2s;
        width: auto;
        font-size: 0.875em;
        padding: 0.6em 1.7em;
        font-family: $riffic;
        @media (max-width: 767px) {
          padding: 0.4em 0.8em;
        }
        @media (max-width: 565px) {
          padding: 0.4em 0.8em;
        }
        &.w100 {
          width: 100%;
          max-width: 355px;
        }
        &:hover {
          box-shadow: none;
          transform: translateY(4px);
          transition: all ease-in 0.2s;
          opacity: 1;
        }
      }
    }
  }
}

.userBox {
  background-color: $black-bg;
  padding: 1.8em 3em 2em;
  border-radius: 15px;
  @media (max-width: 565px) {
    padding: 1.8em 1em 2em;
  }
  img {
    margin-bottom: 3em;
  }
  .form-label {
    color: rgba($color: $white-color, $alpha: 1);
  }
}

.profileBtn {
  .btn {
    margin: 0 0 0;
    box-shadow: 7px 7px 0px 0px #854b1a;
    transition: all ease-in 0.2s;
    width: auto;
    font-size: 1.4em;
    padding: 0.2em 1.5em;
    &.w100 {
      width: 100%;
      max-width: 355px;
    }
    &:hover {
      box-shadow: none;
      transform: translateY(4px);
      transition: all ease-in 0.2s;
      opacity: 1;
    }
  }
}

.languageBtn {
  .btn {
    &.activeLanguage {
      background-color: transparent;
      box-shadow: none !important;
      color: $white-color;
      border-color: $white-color;
    }
  }
}

.primary-text {
  color: $primary-color;
}

.zero-text-stroke {
  -webkit-text-stroke: 0px;
}
.dropdown-bg-white {
  .dropdown-item {
    &:active {
      background-color: $grey;
    }
  }
}
.display-linebreak {
  white-space: pre-line;
}
@media (max-width: 768px) {
  .copyLinkBtn {
    margin-top: 20px;
  }
  .passwordPos {
    svg {
      top: 15px;
    }
  }
  .userBox {
    width: 100% !important;
  }
  .navbar-expand-lg .navbar-nav .btnBox.langFlex .dropdown-menu:after {
    display: none;
  }
  .navbar-expand-lg .navbar-nav .btnBox.langFlex .dropdown-menu {
    position: absolute;
  }
  .episodeSection {
    height: 300px;
    width: 240px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.hover-primary:hover {
  color: $primary-color !important;
}
// Swipper CSS
.swiper-pagination-bullet {
  background-color: white !important;
}
.swiper-button-prev,
.swiper-button-next {
  color: white !important;
  // height: 100% !important;
  // top: 1.4rem !important;

  // background: linear-gradient(
  //   360deg,
  //   #0d0c0f 0.2%,
  //   rgba(13, 12, 15, 0.85) 1.55%,
  //   rgba(13, 12, 15, 0) 50.81%,
  //   rgba(13, 12, 15, 0.28) 70.66%
  // );
  width: 4% !important;
}
.swiper-button-prev {
  left: 0 !important;
  border-radius: 0 0 0 16px;
}
.swiper-button-next {
  right: 5px !important;
  border-radius: 0 0 16px 0;
}
// End swiper CSS

// Dropdown menu class
.dropdown-menu {
  --bs-dropdown-min-width: 7.4rem;
}

.episodes-dropdown {
  .dropdown-item:hover {
    background-color: $primary-color;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .episodeSection {
    height: 320px;
    width: 220px;
  }
}

.active > .page-link {
  background-color: $primary-color;
  border-color: $primary-color;
}
.page-link {
  color: $primary-color;
}
.page-link:hover {
  color: $black-color;
}

/* New Episodes Layout */
.episodes-grid {
  margin-top: 20px;
}

.episodeSectionNew {
  position: relative;
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  width: 280px;
  border-radius: 18px;
  overflow: hidden;
}

.slider_box h5{
  color: #fff
}
/* New Episodes Layout */

/* Modal CSS */
@import "./component/sass/modal.scss";
/* Footer CSS */
@import "./component/sass/footer.scss";
/* Media Quary */
@import "./component/sass/MediaQuary.scss";
