.modal{
    .modal-dialog
    {
        max-width: 600px;
        .modal-content{
            @media (max-width:565px) {
               font-size: 0.8em;
            }
            border-radius: 20px;
            .modal-body
            {
                border-radius: 20px;
                border: 2px solid $white-color; 
                background-color: $black-color;
                text-align: center;
                h4{
                    color: $white-color;
                    font-size: 1.6em;
                }
                h3{
                    margin: 0.7em 0;
                    font-family: $halogen;
                    font-size: 1.2em;
                    color: $white-color;
                    span{
                        color: $primary-color;
                    }
                    &.bigSize
                    {
                        margin-top: -0.2em;
                        font-size: 1.5em;
                        margin-bottom: 0.4em;
                        &.fontWhite
                        {
                            font-size: 1.2em;
                           span{
                            color: $white-color;
                           }
                        }
                    }
                }
                .modalBtn
                {
                    margin-bottom: 1em;
                    .btn{
                        &.cBtn
                        {
                            padding: 0.6em 3.5em;
                            font-size: 0.875em;
                            @media (max-width:565px) {
                                padding: 0.6em 1.5em;
                             }
                            &.btn-outline-primary
                            {
                                color: $white-color;
                                border-color: $white-color;
                                &:hover{
                                    background-color: $primary-color;
                                    border-color: $primary-color;
                                    color: $black-color;
                                }
                            }
                        }
                      
                        &.fullWidth{
                            margin: 0 0 0;
                            box-shadow: 7px 7px 0px 0px #854b1a;
                            transition: all ease-in 0.2s;
                            width: auto;
                            font-size: 1.4em;
                            padding: 0.2em 1.5em;
                            @media (max-width:565px) {
                                font-size: 1.2em;
                              }
                            &.w100{
                                width: 100%;
                                max-width: 355px;
                            }
                            &:hover{
                                box-shadow: none;
                                transform: translateY(4px);
                                transition: all ease-in 0.2s;
                                opacity: 1;
                            }
                        }
                    }
                }
                p{
                    font-family: $halogen;
                    color: #9CA4AB;
                    margin-bottom: 0;
                }
                .paymentBox
                {
                   
                    .textColor
                    {
                    
                        .pay_listradio
                        {
                            background-color: $white-color;
                            padding: 1em;
                            border-radius: 10px;
                            border: 2px solid $primary-color;
                            margin-bottom: 0.6em;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        .form-check
                        {
                            label
                            {
                                width: 95%; 
                                .carrieBox
                                {     
                                    width: 100%;      
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    .car_right
                                    {
                                        color: $black-color;
                                        h5{
                                            font-family: $halogen;
                                        }
                                        h6{
                                            text-align: left;
                                            font-family: $halogen;
                                            margin: 0;
                                            color: #677489;
                                        }
                                    }
                                }
                     
                            }
                        }
                    }
                 
                }
                .cardBox
                {
                    margin-top: 1.5em;
                    .form-label
                    {
                        text-align: left;
                        display: block;
                        color: $white-color;
                    }
                    .agreeText
                    {
                        margin-top: 0.6em;
                        .form-check
                        {
                            text-align: left;
                            color: $white-color;
                            .form-check-input
                            {
                                position: relative;
                                top: 0;
                            }
                            label
                            {
                                position: relative;
                                top: 2px;
                                letter-spacing: 0.5px;
                            }
                        }
                    }
                    
                }
               
            }
          
        }
    }
   &.paymentModal
   {
    .modal-dialog
    {
        .modal-content{
            .modal-body{
                padding: 20px 3em;
                @media (max-width:565px) {
                    padding: 20px 1em;
                }
            }
        }
    }
   }
   &.modalPadding
   {
    .modal-dialog
    {
        .modal-content{
            .modal-body{
                padding: 20px 3em;
                @media (max-width:565px) {
                    padding: 20px 1em;
                }
            }
        }
    }
   }

   .payMent_Text
   {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h2{
        color: $white-color;
        font-size: 1em;
        &.addText
        {
            color: $primary-color;
            cursor: pointer;
            transition: 0.25s ease-out;
            &:hover{
                color: $white-color;
            }
        }
    }
   }
}

.modal-backdrop.show
{
    opacity: 0.8;
}

html {
    overflow-y: scroll;
}
.modal-open {
    padding-right: 0 !important;
    }