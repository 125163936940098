body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rnc {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 255px;
  background-color: #eee;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
}

.rnc-row {
  display: flex;
  align-items: stretch;
  margin: 0 0 10px 0;
  height: 38px;
}

.rnc-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 0 10px;
}

.rnc-canvas {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
}

.rnc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: #fff;
  color: inherit;
  border: none;
  padding: 0;
  width: 25px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
}

.rnc-button svg {
  width: 1em;
  height: 1em;
  fill: #000;
}

.rnc-input {
  border: none;
  padding: 5px 6px;
  font-size: inherit;
  font-family: inherit;
}
