.footer {
  padding: 3.8em 0 2em;
  background-color: $black-color;
  color: $white-color;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  h3 {
    position: relative;
    width: 100%;
    max-width: 400px;
    font-size: 1.875em;
    -webkit-text-stroke: 1.5px #000000;
    line-height: 32px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
    @media (max-width: 767px) {
      font-size: 1.6em;
      margin-bottom: 2em;
    }

    span {
      position: relative;
      z-index: 2;
      span {
        color: $primary-color;
      }
    }
    &::before {
      position: absolute;
      content: "";
      background-image: url("../images/loginsvg.svg");
      width: 100%;
      height: 150px;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: -25px;
      left: 130px;
    }
  }
  .footLink {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 991px) {
      justify-content: center;
      margin-top: 3em;
    }
    @media (max-width: 767px) {
      justify-content: center;
      margin-top: 1em;
    }
    a {
      color: $white-color;
      display: flex;
      align-items: center;
      font-size: 1.1em;
      @media (max-width: 767px) {
        font-size: 0.9em;
      }
      margin-right: 2.9em;
      position: relative;
      text-decoration: none;
      @media (max-width: 767px) {
        margin-right: 2em;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: $primary-color;
        svg {
          path {
            fill: $primary-color;
          }
        }
        &::after {
          position: absolute;
          content: "";
          background-image: url("../images/navimg.svg");
          bottom: 0;
          width: 100%;
          height: 29px;
          background-repeat: no-repeat;
          background-size: contain;
          top: 45px;
        }
      }
      &:hover {
        color: $primary-color;
      }
      svg {
        margin-right: 0.6em;
      }
    }
  }
  .footCopyBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.2em;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    .footCopyright_link {
      @media (max-width: 767px) {
        margin-bottom: 1em;
        text-align: center;
      }
      a {
        color: $white-color;
        text-decoration: none;
        font-family: $halogen;
        font-size: 1em;
        margin-right: 1.5em;
        transition: all 0.5s ease-in;
        opacity: 0.7;
        &.active {
          color: $primary-color;
        }
        // &:last-child {
        //   margin-right: 0;
        // }
        &:hover {
          opacity: 1;
        }
      }
    }
    .footyear {
      h5 {
        opacity: 0.7;
        color: $white-color;
        font-family: $halogen;
        font-size: 1em;
        margin: 0;
      }
    }
  }
  .footSocialSmall {
    margin-top: 3em;
    @media (max-width: 767px) {
      margin-top: 1em;
    }
    .sociallinks {
      justify-content: flex-end;
      @media (max-width: 991px) {
        justify-content: center;
        margin-top: 4em;
        margin-bottom: 1em;
      }

      @media (max-width: 767px) {
        justify-content: center;
        margin-top: 2em;
        margin-bottom: 1em;
      }

      a {
        width: 2em;
        height: 2em;
        border-radius: 5px;
      }
    }
  }
}
@keyframes blink {
  0%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.blinking-text {
  animation: blink 1s infinite;
}

.promo-text {
  font-size: 18px;
}
