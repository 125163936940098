@media only screen and (min-width: 1700px) {
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (max-width: 1280px) {
  .navbar-toggler {
    border-color: $white-color;
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27><path stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/></svg>");
    }
  }

  .navbar-expand-lg {
    .navbar-brand {
      img {
        width: 100%;
      }
    }
    .navbar-nav {
      .btnBox {
        margin-left: 0;
      }
      .nav-link {
        color: $white-color;
        margin-right: 0.4em;
        font-size: 1em;
        &.active {
          color: $primary-color;
        }
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .navbar-expand-lg {
    .navbar-brand {
      img {
        width: 9em;
      }
    }
  }
  .form-label {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 565px) {
}
