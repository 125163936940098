$riffic: "Riffic Free";
$halogen: "Halogen";
$oceanWide: "oceanwide-primer";

@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap');
@font-face {
  font-family: $riffic;
  font-style: normal;
  font-weight: 700;
  src: local("Riffic Free"), url("../fonts/RifficFreeBold.woff") format("woff");
}

@font-face {
  font-family: $halogen;
  font-style: normal;
  font-weight: 700;
  src: local("Halogen"), url("../fonts/Halogen.woff") format("woff");
}
@font-face {
  font-family: $oceanWide;
  font-style: normal;
  font-weight: 600;
  src: local("oceanwide-primer"), url("../fonts/oceanwide-primer.woff") format("woff");
}